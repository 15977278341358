export const createIntersect = ($target, fn) => {
  let hasRendered = false;

  const callback = (entries) => {
    entries.forEach((entry) => {
      const intersecting = entry.isIntersecting;

      if (!hasRendered && intersecting) {
        render();
      }
    });
  };

  const render = () => {
    hasRendered = true;
    fn();
  };

  const observer = new IntersectionObserver(callback);
  observer.observe($target);
};
